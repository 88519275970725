<template>
  <div class="home">
    <section class="banner-section banner-style-two">
      <div class="banner-carousel owl-theme owl-carousel">

        <!-- Slide Item -->
        <div class="slide-item">
          <div class="image-layer" style="background-image:url(/images/main-slider/2-a.jpg)"></div>

          <div class="auto-container">
            <div class="content-box">
              <div class="content">
                <div class="upper-subtitle">- Lord of the Dragons -</div>
                <h1><span>Dragon City</span></h1>
                <div class="lower-subtitle">Collect, breed and train your dragons</div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Slide Item -->
        <div class="slide-item">
          <div class="image-layer" style="background-image:url(/images/main-slider/2.jpg)"></div>
          <div class="auto-container">
            <div class="content-box">
              <div class="content">
                <div class="upper-subtitle">- Lord of the Dragons -</div>
                <h1><span>Dragon City</span></h1>
                <div class="lower-subtitle">- How to train your dragons? -</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Slide Item -->
        <div class="slide-item">
          <div class="image-layer" style="background-image:url(/images/main-slider/1-b.jpg)"></div>

          <div class="auto-container">
            <div class="content-box">
              <div class="content">
                <div class="upper-subtitle">- Build! Hatch! Collect! -</div>
                <h1><span>Dragon City</span></h1>
                <div class="lower-subtitle">There are over 80 million Masters</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="game-view-section">
      <div class="top-pattern-layer"></div>
      <div class="auto-container">
        <!--Platforms-->
        <div class="platforms">
          <div class="row clearfix">
            <!--Platform Block-->
            <div class="platofrm-block col-lg-3 col-md-4 col-sm-6">
              <div class="inner">
                <div class="icon-box"><span class="flaticon-play"></span></div>
                <div class="p-title">Newest<br>Dragons</div>
                <a href="/dragons" class="over-link"></a>
              </div>
            </div>
            <!--Platform Block-->
            <div class="platofrm-block col-lg-3 col-md-4 col-sm-6">
              <div class="inner">
                <div class="icon-box"><span class="flaticon-xbox-logo"></span></div>
                <div class="p-title">Current<br>Events</div>
                <a href="/dragons" class="over-link"></a>
              </div>
            </div>
            <!--Platform Block-->
            <div class="platofrm-block col-lg-3 col-md-4 col-sm-6">
              <div class="inner">
                <div class="icon-box"><span class="flaticon-steam-logo"></span></div>
                <div class="p-title">Upcoming<br>Events</div>
                <a href="/dragons" class="over-link"></a>
              </div>
            </div>
            <!--Platform Block-->
            <div class="platofrm-block col-lg-3 col-md-4 col-sm-6">
              <div class="inner">
                <div class="icon-box"><span class="flaticon-playstore-2"></span></div>
                <div class="p-title">Top 10 strongest <br>dragons</div>
                <a href="/dragons" class="over-link"></a>
              </div>
            </div>
          </div>
        </div>
        <!--Game Awards-->
        <div class="game-awards">
          <!--Title-->
          <div class="sec-title centered">
            <h2>Dragon City AWARD</h2><span class="bottom-curve"></span>
          </div>

          <div class="awards">
            <div class="row clearfix">
              <!--Award Block-->
              <div class="award-block col-lg-3 col-md-6 col-sm-12">
                <div class="inner">
                  <div class="upper-title">design <br>award</div>
                  <div class="lower-title">winner 2014</div>
                </div>
              </div>
              <!--Award Block-->
              <div class="award-block col-lg-3 col-md-6 col-sm-12">
                <div class="inner">
                  <div class="upper-title">Apple store</div>
                  <div class="lower-title">game of the <br>year</div>
                </div>
              </div>
              <!--Award Block-->
              <div class="award-block col-lg-3 col-md-6 col-sm-12">
                <div class="inner">
                  <div class="upper-title">google play <br>best</div>
                  <div class="lower-title">developer</div>
                </div>
              </div>
              <!--Award Block-->
              <div class="award-block col-lg-3 col-md-6 col-sm-12">
                <div class="inner">
                  <div class="upper-title">MGA finalist</div>
                  <div class="lower-title">best audio <br>visual</div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>

    <section class="faq-section">
      <div class="auto-container">
        <!--Title-->
        <div class="sec-title centered">
          <h2>HEROIC RACE FAQ</h2><span class="bottom-curve"></span>
        </div>

        <div class="faq-container">
          <div class="accordion-box">
            <!--Block-->
            <div class="accordion block current wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
              <div class="acc-btn active">What does Pool Mean??<div class="icon flaticon-cross"></div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">In the heroic race, the pool is the maximum number of items available to collect at
                    the very start of the mission.
                    The pool always starts off full
                    Once you start emptying the pool, new items will fill the pool according to the wait time
                    You cannot collect any items if the pool is empty - you will have to wait for the pool to fill back
                    up
                    the pool must have at least one item in it to collect.
                    Use the lap information and remember details about the pool, the game will not tell you this
                    information.</div>
                </div>
              </div>
            </div>

            <!--Block-->
            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">What does Wait time mean??<div class="icon flaticon-cross"></div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">Wait time is the amount of time required for a new item to be added to the pool.
                    This only matters when the pool is not big enough to complete the mission in one go. the pool starts
                    regenerating new items as soon as you collect your first item. BUT it will reset that cooldown every
                    time you collect an item! So start any timers only after depleting the pool completely.</div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">What is Min. Time??<div class="icon flaticon-cross"></div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">Minimum time is a calculation of the quickest time the pool will refill to complete
                    the mission the calculation is (RequiredItems - Pool) * Wait Time Once you empty the pool, start a
                    timer because you have to wait this long for items to regenerate.</div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">Best way to collect gold for Heroic Race tasks?<div class="icon flaticon-cross">
                </div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">The best way to collect gold is by having more habitats. Each time you collect gold
                    you have a chance to collect an item! You also have a higher chance to collect gold when your
                    habitat is full of gold. This is visible when the gold icon is no longer see-through. Collecting
                    gold is one of the easiest items to collect for the Heroic Race. Having more habitats = more chances
                    to get items from collecting gold.
                    <br><br>Another way to quickly increase the amount of gold you can collect is to power up your
                    dragons You can save your power ups for the tasks in the heroic race that require you to power up
                    dragons. But you should priorities dragons that give the most gold per minute Note that once a
                    dragons level increases above level 10 - the increase in gold / level halves. Your rarest dragons
                    usually will pay the most gold per minute.
                    <br><br>You can also try and focus on habitats like Terra habitats which get full quicker, but its
                    not always the best strategy, because its not great once the heroic race ends!</div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">Best way to collect food for Heroic Race tasks?<div class="icon flaticon-cross">
                </div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">
                    The best way to collect food is by buying the cheapest option over and over again. Each time you
                    collect food you have a chance to collect an item, so buy the 50 gold option in every food farm and
                    do it fastest!
                    <br><br>You have a higher chance to get items the more food purchased but the bigger foods takes
                    much longer to grow and claim. That's why its advised to always use the 50 gold option called
                    "Backyard Garderner". Collecting food more often = more chances to get items from collecting food.
                    <br><br>
                    If you still really want to grow large food options, then maybe consider buying 1 very expensive
                    food option, that takes 9, 12 or 24 hours. Meanwhile you can use every other farm to cycle the 50
                    gold food collection.
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">Best way to breed dragons for Heroic Race tasks?<div class="icon flaticon-cross">
                </div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">
                    The best way to breed dragons is to use Terra + Terra. It may take many tries, and failed attempts,
                    but its the quickest way to get multiple items.
                    Once you've bred the Terra + Terra dragons - you can sell the egg after hatch time (if you don't
                    need hatching items) - or place them on Terra habitats, they can be powered up later too.
                    <br><br>
                    For each item, expect to breed up to 30-40 times. it takes a lot of attempts! The alternative is to
                    use the slower way with long breeds to guarantee an item, but other players might overtake you while
                    you do this.
                    <br><br>
                    Long term goal: Try to unlock the 3 available breeding areas when they become available via gems
                    from completing rewards / surveys (this can take a long time - or you could spend money, but that is
                    not a great use of money). Breeding more often = more chances to get items from breeding.
                    <br><br>
                    If you manage to unlock all of the breeding buildings, and all slots on the hatchery, place them all
                    near each other (this will be near the second island due to the breeding sanctuary)
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">Best way to hatch dragons for Heroic Race tasks?<div class="icon flaticon-cross">
                </div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">
                    The best way to hatch dragons is to use Terra + Terra that you've bred. Again, it will take many
                    tries, and failed attempts, but its the quickest way to get multiple items. For each item, expect to
                    hatch up to 30-40 times. it takes a lot of attempts. Otherwise the slower way is to use other long
                    eggs to guarantee an item, but other players might overtake you. Try to unlock the 3 available
                    breeding areas AND upgraded hatchery (5 eggs at once) when they become availble.
                    <br><br>
                    If you manage to unlock all of the breeding buildings, and all slots on the hatchery, place them all
                    near each other (this will be near the second island due to the breeding sanctuary)
                    <br><br>
                    Best way to feed dragons for Heroic Race tasks? The best way to feed dragons is to use the Terra
                    dragons you hatched in the previous missions. But if you didn't use this tactic, then another option
                    is to power up other dragons that are already on low level. the lower the level of dragon the less
                    food it costs - but it takes more luck to get items. I find powering up level 1 dragons to level 6
                    takes little food, but can give 5-6 items.
                    <br><br>
                    One thing I do outside of Heroic Races is leave all my newly acquired dragons at level 1 - and only
                    power up dragons when the Heroic Race is on. This is a great way to complete the task, because these
                    dragons needed powering up anyway
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">Best way to Battle Dragons in the Heroic Race?<div class="icon flaticon-cross"></div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">The best way to battle dragons is to set yourself a timer reminding you when the
                    next battle is. Use the information above to see which battle will take the longest. A popular
                    tactic is to use your free spin when you have the longest battle to complete. <br><br>Using the free
                    spin guarantees at least 1 task will be auto-completed, doing this against the longest battle saves
                    the most time and is most efficient.</div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">Best way to Complete Temporary Quest battles for the Heroic Race?<div
                  class="icon flaticon-cross"></div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">
                    The Temporary Quest battle items are the least frequent task in the early stages of the Heroic Race.
                    Sometimes you will want to be always completing your Temporary quests because they have good rewards
                    (Like food, dragon orbs or collection items ) completing temporary quests is also a great way to
                    level up dragons ranks.
                    <br><br>
                    However one of the best things to do is always make sure you don't have Temporary quests on cooldown
                    for the heroic race
                    <br>Otherwise you will be waiting until the quest allows you to battle again
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion block wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
              <div class="acc-btn">Best way to Complete PVP League battles for the Heroic Race?<div
                  class="icon flaticon-cross"></div>
              </div>
              <div class="acc-content">
                <div class="content">
                  <div class="text">
                    The best way to complete PVP for the Heroic race is to have fully powered up dragons and full
                    charges to complete League. Sometimes League Battles can be really hard, other players have full
                    heroic dragons at high levels. You have to decide if you want to reset your League battles or not -
                    this will give you a fresh set of players to verse. it will however not refresh your charges (only
                    gems and beating everyone will refresh your battle limit).
                    <br><br>
                    You can also aim towards unlocking the 6th Dragon Tower (Winstance - 20%+ Life and Damage) which
                    makes this task a little easier! Make sure you only complete the required number of League battles
                    and not too many It could slow you down if you have to wait for your League counter to recharge
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {

    },
    mounted() {
      window.homeLoading()
    }
  }
</script>